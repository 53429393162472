const namespace = 'contracts';

export const GET_CONTRACTS_BY_TOKEN = `${namespace}/By Token Get`;
export const GET_CONTRACTS_BY_TOKEN_SUCCESS = `${namespace}/By Token Get Success`;
export const GET_CONTRACTS_BY_TOKEN_FAIL = `${namespace}/By Token Get Fail`;

export const SIGN_CONTRACT = `${namespace}/Sign`;
export const SIGN_CONTRACT_SUCCESS = `${namespace}/Sign Success`;
export const SIGN_CONTRACT_FAIL = `${namespace}/Sign Fail`;

export const PRE_SIGN_CONTRACT = `${namespace}/Pre-Sign`;
export const PRE_SIGN_CONTRACT_SUCCESS = `${namespace}/Pre-Sign Success`;
export const PRE_SIGN_CONTRACT_FAIL = `${namespace}/Pre-Sign Fail`;
