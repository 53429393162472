import reducer from "../core/reducer";

import {
  GET_CONTRACTS_BY_TOKEN,
  GET_CONTRACTS_BY_TOKEN_FAIL,
  GET_CONTRACTS_BY_TOKEN_SUCCESS,
  SIGN_CONTRACT,
  SIGN_CONTRACT_FAIL,
  SIGN_CONTRACT_SUCCESS,
  PRE_SIGN_CONTRACT,
  PRE_SIGN_CONTRACT_FAIL,
  PRE_SIGN_CONTRACT_SUCCESS,
} from "./constants";

export default reducer({
  actions: [
    {
      name: 'getByTokenRequest',
      action: GET_CONTRACTS_BY_TOKEN,
      endPoint: 'contract/by-token/{token}',
      mutations: {
        fail: GET_CONTRACTS_BY_TOKEN_FAIL,
        success: GET_CONTRACTS_BY_TOKEN_SUCCESS,
      },
    },
    {
      method: 'post',
      name: 'preSignRequest',
      action: PRE_SIGN_CONTRACT,
      endPoint: 'contract/pre-sign/{id}',
      mutations: {
        fail: PRE_SIGN_CONTRACT_FAIL,
        success: PRE_SIGN_CONTRACT_SUCCESS,
      },
    },
    {
      method: 'postUpload',
      name: 'signRequest',
      action: SIGN_CONTRACT,
      endPoint: 'contract/sign/{id}',
      mutations: {
        fail: SIGN_CONTRACT_FAIL,
        success: SIGN_CONTRACT_SUCCESS,
      },
    },
  ]
});
