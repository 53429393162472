const namespace = 'payments';

export const ACTIVATE_CITY_TAX_PAYMENT = `${namespace}/Activate City Tax Payment`;
export const ACTIVATE_CITY_TAX_PAYMENT_SUCCESS = `${namespace}/Activate City Tax Payment Success`;
export const ACTIVATE_CITY_TAX_PAYMENT_FAIL = `${namespace}/Activate City Tax Payment Fail`;

export const CONFIRM_CITY_TAX_PAYMENT = `${namespace}/Confirm City Tax Payment`;
export const CONFIRM_CITY_TAX_PAYMENT_SUCCESS = `${namespace}/Confirm City Tax Payment Success`;
export const CONFIRM_CITY_TAX_PAYMENT_FAIL = `${namespace}/Confirm City Tax Payment Fail`;

export const ACTIVATE_RESERVATIONS_PAYMENT = `${namespace}/Activate Reservations Payment`;
export const ACTIVATE_RESERVATIONS_PAYMENT_SUCCESS = `${namespace}/Activate Reservations Payment Success`;
export const ACTIVATE_RESERVATIONS_PAYMENT_FAIL = `${namespace}/Activate Reservations Payment Fail`;

export const CONFIRM_RESERVATIONS_PAYMENT = `${namespace}/Confirm Reservations Payment`;
export const CONFIRM_RESERVATIONS_PAYMENT_SUCCESS = `${namespace}/Confirm Reservations Payment Success`;
export const CONFIRM_RESERVATIONS_PAYMENT_FAIL = `${namespace}/Confirm Reservations Payment Fail`;
